import moment from 'moment'

export default {
  data () {
    return {
      currentTime: null
    }
  },
  mounted () {
    if (process.browser) {
      this.currentTime = moment().format('HH:mm') + ':00'
      this.currentTimeInterval = setInterval(() => {
        this.currentTime = moment().format('HH:mm') + ':00'
      }, 30000)
    }
  },
  beforeDestroy () {
    if (process.browser) {
      clearInterval(this.currentTimeInterval)
    }
  }
}

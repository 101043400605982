import { mapActions } from 'vuex'
import moment from 'moment'
import { purityTypes } from '~/core/utils/BuyerAccount'

const chunk = (array, size = 1) => {
  const chunkedValues = []
  for (let i = 0; i < array.length; i += size) {
    const group = []
    // prevents the loop from adding undefined values in the group array
    const length = i + size > array.length ? array.length : i + size
    for (let j = i; j < length; j++) {
      group.push(array[j])
    }
    chunkedValues.push(group)
  }
  return chunkedValues
}

export default {
  mounted () {
    this.rateInterval = setInterval(() => {
      this.getLocal()
    }, 30000)
  },
  beforeDestroy () {
    if (process.browser) {
      clearInterval(this.rateInterval)
      window.removeEventListener('resize', this.resizeWindow)
    }
  },
  data () {
    return {
      tableData: [],
      discount995: 0,
      discount999: 0,
      purityTypes,
      localStatus: '',
      localSilverData: []
    }
  },
  watch: {
    currentUser (newValue, oldValue) {
      this.getLocal()
    }
  },
  methods: {
    ...mapActions({
      local: 'buyerAccount/dashboard/local'
    }),
    async getUserDiscount () {
      if (!this.currentUser) {
        this.discount999 = 0
        this.discount995 = 0
        return
      }
      try {
        const ugroup = await this.$axios.$get('/preorder/prices', {
          params: {
            user_id: this.currentUser.user_id
          }
        })
        if (ugroup) {
          this.discount999 = ugroup.discount
          this.discount995 = ugroup.discount995
        }
      } catch (error) {
        console.error('Error fetching pending orders:', error)
      }
    },
    goToBuyerAccount () {
      if (!this.currentUser) {
        return this.$store.commit('ui/setModal', 'auth')
      }
      this.$router.push({ path: this.localePath('/buyer-account') })
    },

    async getLocal () {
      await this.getUserDiscount()
      const { data, status } = await this.local()
      this.localStatus = status
      let filtredData = []
      const filtredDataSilver = []
      data.forEach((element) => {
        this.purityTypes.forEach((purityElem) => {
          if (purityElem.id === element.id) {
            element = {
              ...purityElem,
              ...element
            }
          }
        })
        if (element.id === 1) {
          element.sell -= this.discount999
        }
        if (element.id === 2) {
          element.sell -= this.discount995
        }
        if (element.id === 13 || element.id === 14) {
          filtredDataSilver.push(element)
        } else {
          filtredData.push(element)
        }
      })
      const element = filtredData.find(e => e.id === 7)
      const element585 = filtredData.find(e => e.id === 8)
      if (element || element585) {
        filtredData = filtredData.filter(e => element.id !== e.id)
      }
      if (this.isMobile) {
        filtredData.splice(2, 0, element585)
        this.tableData = chunk(filtredData, 3).slice(0, 1)
        this.localSilverData = chunk(filtredDataSilver, 1).slice(0, 1)
      } else {
        filtredData.splice(2, 0, element585)
        this.tableData = chunk(filtredData, 3).slice(0, 1)
        this.localSilverData = chunk(filtredDataSilver, 1).slice(0, 1)
      }
      if (process.browser) {
        this.resizeWindow = window.addEventListener('resize', () => {
          if (this.isMobile) {
            filtredData.splice(2, 0, element585)
            this.tableData = chunk(filtredData, 3).slice(0, 1)
            this.localSilverData = chunk(filtredDataSilver, 1).slice(0, 1)
          } else {
            filtredData.splice(2, 0, element585)
            this.tableData = chunk(filtredData, 3).slice(0, 1)
            this.localSilverData = chunk(filtredDataSilver, 1).slice(0, 1)
          }
        })
      }
    },
    renderYesterdayPrice (tPrice, yPrice) {
      const result = tPrice - yPrice === 0 ? 0 : (tPrice - yPrice).toFixed(2)
      const isNegative = result <= 0
      return {
        price: isNegative ? result : '+' + result,
        isNegative
      }
    },
    isClosed () {
      return this.localStatus === 'close'
    },
    formatData (data) {
      return moment(data).format('HH:MM:SS')
    },
    renderCurrentData () {
      return moment().format('YYYY-MM-DD')
    }
  }
}
